/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

@layer base {
  :root {
    --transparent: 0 0 0 / 0;
    --color-overlay: 29 29 29 / 30%;
    --color-transparent: rgb(0 0 0 / 0);
    --color-white: #ffffff;
    --color-black: #000000;
    --color-neutral-50: #fafafa;
    --color-neutral-100: #f5f5f5;
    --color-neutral-200: #e5e5e5;
    --color-neutral-300: #d4d4d4;
    --color-neutral-400: #a3a3a3;
    --color-neutral-500: #737373;
    --color-neutral-600: #525252;
    --color-neutral-700: #404040;
    --color-neutral-800: #262626;
    --color-neutral-900: #171717;
    --color-neutral-950: #0a0a0a;
    --ds-button-foreground-disabled: var(--color-neutral-500);
    --ds-button-background-disabled: var(--color-neutral-100);
    --ds-button-border-disabled: var(--color-neutral-100);

    --font-sans: 'Proxima Nova';
    --font-serif: 'Georgia';
    --font-mono: 'Menlo';
    --font-headline: 'Loew';
    --font-headline-brand: 'Simple Joys';
  }

  html[lang^='ar'] body[class^='theme-'] {
    --font-sans: 'Sans Arabic';
    --font-headline: 'Sans Arabic';
    --font-headline-brand: 'Massira Pen';
  }

  html[lang^='ko'] body[class^='theme-'] {
    --font-sans: 'Yd Gothic';
    --font-headline: 'Yd Gothic';
    --font-headline-brand: 'Nanum Pen';
  }

  html[lang^='th'] body[class^='theme-'] {
    --font-sans: 'Neue Frutiger';
    --font-headline: 'Neue Frutiger';
    --font-headline-brand: 'Neue Frutiger';
  }

  html[lang^='zh'] body[class^='theme-'] {
    --font-headline: 'Fz Chao Cu Hei';
  }

  html[lang^='ru'] body[class^='theme-'] {
    --font-sans: 'Proxima Nova Cyrillic';
  }
}
